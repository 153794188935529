/*TradeGothic Regular | $ftci-font-regular*/
@font-face {
  font-family: "TradeGothicLTStd";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd.woff") format("woff");
  font-display: swap;
}

/*TradeGothic Bold | $ftci-font-bold*/
@font-face {
  font-family: "TradeGothicLTStd-Bold";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-Bold.woff") format("woff");
  font-display: swap;
}

/*TradeGothic Bold2 | $ftci-font-bold2*/
@font-face {
  font-family: "TradeGothicLTStd-Bd2";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-Bd2.woff") format("woff");
  font-display: swap;
}

/*TradeGothic Condensed Bold | $ftci-font-condensed-bold*/
@font-face {
  font-family: "TradeGothicLTStd-BdCn20";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-BdCn20.woff") format("woff");
  font-display: swap;
}

/*TradeGothic Condensed | $ftci-font-condensed*/
@font-face {
  font-family: "TradeGothicLTStd-Cn18";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-Cn18.woff") format("woff");
  font-display: swap;
}

/*TradeGothic Narrow Light | $ftci-font-narrow-light-obl*/
@font-face {
  font-family: "TradeGothicLTStd-Cn18Obl";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-Cn18Obl.woff") format("woff");
  font-display: swap;
}

/*TradeGothic Oblique | $ftci-font-oblique*/
@font-face {
  font-family: "TradeGothicLTStd-Obl";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-Obl.woff") format("woff");
  font-display: swap;
}

/*TradeGothic Oblique Bold | $ftci-font-oblique-bold*/
@font-face {
  font-family: "TradeGothicLTStd-BoldObl";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-BoldObl.woff") format("woff");
  font-display: swap;
}

/*TradeGothic light | $ftci-font-light*/
@font-face {
  font-family: "TradeGothicLTStd-Light";
  src: url("../../fonts/trade-gothic/TradeGothicLTStd-Light.woff") format("woff");
  font-display: swap;
}
