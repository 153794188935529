/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

@import "theme-variables";
@import "~@angular/material/theming";
@include mat-core();

* {
  outline: none;
}

$omni-app-primary: mat-palette($mat-blue-grey);
$omni-app-accent: mat-palette($mat-pink, A200, A100, A400);
$omni-app-warn: mat-palette($mat-red);
$omni-app-theme: mat-light-theme($omni-app-primary, $omni-app-accent, $omni-app-warn);
@include angular-material-theme($omni-app-theme);

/*Genaric Styles*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

img {
  height: intrinsic;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  cursor: pointer;
}

html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: $ftci-font-regular !important;
  background: $white;
  color: color(secondary);
  background-color: $secondary-bg;

  .main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;

    .page-viewport {
      height: 100%;
      overflow-x: hidden;

      .cdk-virtual-scroll-content-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .page-container {
          width: 100%;
          flex-grow: 1;
          display: flex;
          background: #f4f4f4;
          box-sizing: border-box;

          .page-selector {
            display: flex;
            flex-grow: 1;
            width: 100%;

            .page-inner {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              padding-top: 40px;
              padding-bottom: 44px;
              margin: 0 calc(3rem - 0px) 0 3rem;

              @include for-desktop-small {
                padding-top: 32px;
                padding-bottom: 36px;
              }

              @include for-tablet-landscape-up {
                padding-top: 24px;
                padding-bottom: 28px;
              }

              @include for-tablet-landscape-up {
                margin: 0 calc(1.5rem - 0px) 0 1.5rem;
              }

              @include for-tablet-portrait-up {
                margin: 0;
              }

              @include for-phone-only {
                margin: 0;
              }

              .account-context-container {
                display: flex;
                align-items: center;
                background-color: $white;
                box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
                padding: 16px 40px 19px 40px;
                margin-bottom: 24px;

                @include for-tablet-landscape-up {
                  padding: 13px 24px 20px 24px;
                }

                @include for-tablet-portrait-up {
                  flex-direction: column;
                  margin-top: 0;
                  padding: 24px 0;
                  min-height: 56px;
                  align-items: center;
                  justify-content: center;

                }

                @include for-phone-only {
                  flex-direction: column;
                  margin-top: 0;
                  padding: 24px 0;
                  min-height: 52px;
                  align-items: center;
                  justify-content: center;
                }

                .account-owner {
                  position: relative;
                  top: 4px;
                  margin-left: 34px;

                  @include for-desktop-small {
                    top: 6px;
                  }

                  @include for-tablet-landscape-up {
                    top: 6px;
                  }

                  @include for-tablet-portrait-up {
                    margin: 0 24px;
                    text-align: center;
                  }

                  @include for-phone-only {
                    margin: 0 16px;
                    text-align: center;
                  }
                }
              }

              .body-cont {
                flex-grow: 1;
                min-height: 560px;
                background: $white;
                box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05) !important;
                // border: 1px solid #d6d5d5;
                padding: 40px;
                position: relative;

                @include for-desktop-small {
                  padding: 32px 24px;
                }

                @include for-tablet-landscape-up {
                  padding: 24px;
                }

                @include for-tablet-portrait-up {
                  border-bottom: 0px solid rgba(100, 100, 100, 0.5);
                  box-shadow: none;
                  padding: 20px 24px;
                }

                @include for-phone-only {
                  border-bottom: 0px solid rgba(100, 100, 100, 0.5);
                  box-shadow: none;
                  padding: 20px 16px;
                }

                .table-filter {
                  display: flex;
                  flex-direction: row;
                  padding: 0 0 32px;

                  .table-filter-left {
                    display: flex;
                    align-items: center;
                    width: 65%;

                    @include for-tablet-landscape-up {
                      width: 90%;
                    }

                    @include for-tablet-portrait-up {
                      flex-direction: column;
                      width: 100%;
                      max-width: 100% !important;
                    }

                    @include for-phone-only {
                      flex-direction: column;
                      width: 100%;
                      max-width: 100% !important;
                    }

                    .ft-dropdown {
                      margin-right: 24px;
                      color: color(primary);

                      @include for-tablet-portrait-up {
                        display: block;
                        //margin-right: 0;
                      }

                      @include for-phone-only {
                        display: block;
                        //margin-right: 0;
                      }
                    }

                    .ft-dropdown-date {
                      margin-right: 24px;

                      @include for-tablet-portrait-up {
                        display: block;
                        width: 100%;
                        color: color(primary);
                        margin-bottom: 26px;
                        margin-right: 0;

                        .main-dropdown-container {
                          .link-button {
                            width: 100%;
                          }
                        }
                      }

                      @include for-phone-only {
                        display: block;
                        width: 100%;
                        color: color(primary);
                        margin-bottom: 26px;
                        margin-right: 0;

                        .main-dropdown-container {
                          .link-button {
                            width: 100%;
                          }
                        }
                      }
                    }

                    .ft-search-input {
                      @include for-tablet-portrait-up {
                        width: 100%;
                      }

                      @include for-phone-only {
                        width: 100%;
                      }
                    }
                  }

                  .table-filter-right {
                    width: 35%;

                    @include for-tablet-landscape-up {
                      width: 10%;
                    }
                  }
                }

                .action-header {
                  text-align: right;
                  margin-bottom: 48px;
                  position: relative;
                  display: flex;

                  @include for-desktop-small {
                    margin-bottom: 40px;
                  }

                  @include for-tablet-landscape-up {
                    margin-bottom: 40px;
                  }

                  @include for-tablet-portrait-up {
                    background: $white !important;
                    padding: 0;
                    height: auto;
                    text-align: center !important;
                    margin: 0;
                    flex-direction: column !important;
                  }

                  @include for-phone-only {
                    background: $white !important;
                    padding: 0;
                    height: auto;
                    text-align: center !important;
                    margin: 0;
                    flex-direction: column !important;
                  }

                  .mat-icon.group {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                  }

                  .section-icon {
                    font-size: 0.875rem;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    position: relative;
                    top: 4px;
                  }

                  .link-button {
                    display: inline-block;
                    width: auto;
                    color: inherit;
                    padding: 0;
                    border-radius: 0;
                    margin: 0px 12px 0px 5px;
                    text-decoration: none;
                    position: relative;
                    top: 5px;
                    border: 0;
                    background: transparent;

                    .mat-button-wrapper {
                      span {
                        border-bottom: 1px solid color(light);
                        margin-left: 8px;
                      }
                    }

                    a {
                      color: color(secondary);
                      text-decoration: none;
                    }
                  }

                  .action-button {
                    font-size: 1rem;
                    padding-left: 24px !important;
                    width: auto;
                    position: relative;
                    top: -4px;

                    @include for-desktop-small {
                      padding-left: 20px;
                    }

                    @include for-tablet-landscape-up {
                      padding-left: 20px;
                    }

                    @include for-tablet-portrait-up {
                      display: none;
                    }

                    @include for-phone-only {
                      display: none;
                    }

                    mat-icon {
                      font-size: 1rem;
                      width: 16px;
                      height: 16px;
                    }
                  }

                  .action-header-left-cont {
                    display: flex;
                    align-items: baseline;
                    justify-content: flex-start;
                    width: 65%;

                    @include for-tablet-portrait-up {
                      justify-content: center;
                      padding: 4px 24px 24px 24px;
                      align-items: center;
                      margin-bottom: 0 !important;
                      width: auto;
                    }

                    @include for-phone-only {
                      justify-content: center;
                      padding: 8px 16px 28px 16px;
                      align-items: center;
                      margin-bottom: 0 !important;
                      width: auto;
                    }

                    .account-owner {
                      margin-top: 6px;
                    }
                  }

                  .action-header-right-cont {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 35%;

                    @include for-tablet-landscape-up {
                      flex-direction: column;
                      align-items: flex-end;
                    }

                    @include for-tablet-portrait-up {
                      flex-direction: column;
                      justify-content: center;
                      padding: 0;
                      margin: 0 12.5%;
                      width: auto;
                    }

                    @include for-phone-only {
                      flex-direction: column;
                      justify-content: center;
                      padding: 0;
                      margin: 0 16;
                      width: auto;
                    }
                  }
                }
              }

              .body-cont.list-style {
                @include for-tablet-portrait-up {
                  border-bottom: 0px solid rgba(100, 100, 100, 0.5);
                  box-shadow: none; // action-header
                  padding: 0;
                  margin-bottom: 24px;
                }

                @include for-phone-only {
                  border-bottom: 0px solid rgba(100, 100, 100, 0.5);
                  box-shadow: none; // action-header
                  padding: 0;
                  margin-bottom: 24px;
                }
              }
            }
          }
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $ftci-font-regular;
  }

  p,
  ul,
  li,
  ol {
    //font-family: $ftci-font-regular;
    @extend .paragraph-regular;
  }

  strong {
    font-family: $ftci-font-bold2;
  }

  h1 {
    @extend .h1-r;
  }

  h2 {
    @extend .h2;
  }

  h3 {
    @extend .h3;
  }

  .text-right {
    text-align: right;
  }

  /* Buttons Start */
  button {
    cursor: pointer;
    box-shadow: none !important;
  }

  button.btn {
    border: 0;
    background: transparent;
  }

  button.link-button {
    width: auto;
    border: 0;
    margin: 0;
    box-shadow: none;
    background: transparent !important;
    cursor: pointer;
    @extend .paragraph-bold;
    color: color(primary) !important;

    &:hover {
      text-decoration: underline;
    }
  }

  button.blank-button {
    box-shadow: none;
    color: color(primary);
    font-family: $ftci-font-bold2;
    font-size: 0.9rem;
    background: transparent !important;
    line-height: 10px;
    margin-top: 1.6rem;
    padding: 4px 20px;
    border-radius: 0;

    @include for-phone-only {
      margin-bottom: 10%;
    }

    .mat-button-ripple,
    .mat-button-focus-overlay {
      display: none !important;
    }
  }

  button,
  .mat-button {

    .mat-button-ripple,
    .mat-button-focus-overlay {
      display: none !important;
    }
  }

  .passError {
    list-style-type: disc;
    font-size: 14px;
    display: list-item;
    font-weight: normal !important;
  }

  .button-style {
    min-width: 90px;
    border-radius: 0;
    padding: 10px 32px 5px 32px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;

    @include for-desktop-small {
      padding: 11px 28px 7px 28px;
    }

    @include for-tablet-landscape-up {
      padding: 13px 28px 8px 28px;
    }

    @include for-tablet-portrait-up {
      padding: 14px 24px 9px 24px;
    }

    @include for-phone-only {
      padding: 14px 24px 9px 24px;
    }

    .mat-button-focus-overlay {
      background: transparent !important;
    }
  }

  button.button {
    @extend .paragraph-c;
    @extend .button-style;
    background: transparent;
    color: color(secondary);
    border: solid 0px transparent;
  }

  a.primary-button,
  label.primary-button,
  button.primary-button {
    @extend .paragraph-c;
    @extend .button-style;
    background: color(primary);
    color: $white !important;
    border: solid 1px color(primary);

    .mat-button-wrapper {
      @extend .paragraph-c;
      color: $white !important;
    }

    &:hover {
      background: color(light) !important;
      border: solid 1px color(light);

      .mat-button-wrapper {
        color: $white;
      }
    }
  }

  button.primary-button[disabled] {
    opacity: 0.5 !important;
  }

  a.danger-button,
  button.danger-button {
    @extend .paragraph-c;
    @extend .button-style;
    background: $white;
    color: color(error) !important;
    border: solid 1px color(error);

    .mat-button-wrapper {
      @extend .paragraph-c;
      color: color(error) !important;
    }

    &:hover,
    &:focus {
      background: color(light) !important;
      border: solid 1px color(light);
      color: $white !important;

      .mat-button-wrapper {
        color: $white !important;
      }
    }
  }

  button.danger-button[disabled] {
    opacity: 0.5 !important;
  }

  a.secondary-button,
  label.secondary-button,
  button.secondary-button {
    @extend .paragraph-c;
    @extend .button-style;
    background: $white;
    color: color(secondary) !important;
    border: solid 1px color(light);

    &:hover {
      background: color(light) !important;
      color: $white !important;

      .mat-button-wrapper {
        color: $white;
      }
    }

    .mat-button-focus-overlay {
      background: transparent !important;
    }
  }

  button.secondary-button[disabled] {
    opacity: 0.5 !important;
  }

  a.btn-lg,
  button.btn-lg,
  label.btn-lg {
    @extend .paragraph-lg-c;
    padding: 12px 36px 7px 36px;
  }

  .mat-stroked-button:hover,
  .mat-stroked-button:focus {
    background: color(primary) !important;
    border-color: transparent !important;
  }

  /* Buttons End */

  /*utility classes*/
  .clickable {
    cursor: pointer;
  }

  .not-clickable {
    cursor: default !important;
  }

  .hidden-element {
    display: none !important;
  }

  .light-text {
    color: $white !important;
  }

  .no-scroll {
    overflow: hidden !important;
    margin-right: 0px;
  }

  .no-scroll-body {
    overflow-y: scroll !important;
    position: fixed;
    width: 100%;
  }

  .hidden-scroll-body {
    overflow-y: hidden !important;
  }

  .clear-fix {
    clear: both;
  }

  .mat-icon-button {
    border-radius: 0;
  }

  .flex-spacer {
    flex-grow: 1;
  }

  /*colors */
  .color-primary {
    color: color(primary) !important;
  }

  b {
    font-family: $ftci-font-bold2 !important;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: color(light) !important;
  }

  /* Margins */
  .margin-top-20 {
    margin-top: 20px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .no-margin {
    margin: 0 !important;
  }

  /* Spacers, Deviders etc */
  .spacer {
    display: block;
    height: 40px;
  }

  /*success & Error*/
  .success {
    color: color(success);
  }

  .error {
    color: color(error);
  }

  .no-border-bottom {
    border-bottom: 0 !important;
  }

  /* Mat Dialog */
  .mat-dialog-container {
    padding: 40px;
    border-radius: 0;
    //min-height: 527px;
  }

  .idle-alert-dialog {
    width: 480px;

    @include for-phone-only {
      width: 360px;
    }
  }

  /*mat-cell color overwrite*/
  .mat-cell,
  .mat-footer-cell {
    color: color(secondary) !important;
  }

  /*utility classes end*/
}



/* CDK Overlay Start */
.cdk-overlay-container {
  z-index: 99999;

  .cdk-overlay-pane {

    .mat-menu-panel {
      border-radius: 0px;
      min-height: auto;
      min-width: 278px !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      max-height: 336px !important;
      overflow: hidden;
      padding: 16px 10px 16px 8px;

      @include for-desktop-small {
        min-width: 260px !important;
      }

      @include for-tablet-landscape-up {
        min-width: 260px !important;
      }

      @include for-tablet-portrait-up {
        min-width: 260px !important;
        margin-top: -60px;
        min-width: 180px;
      }

      @include for-phone-only {
        min-width: 260px !important;
        margin-top: -60px;
        min-width: 180px;
      }

      .mat-menu-content {
        padding: 0px;

        .ft-search-input {
          .search-container {
            margin-bottom: 10px;

            .mat-form-field {
              width: 100%;

              @include for-desktop-small {
                width: 100%;
              }

              @include for-tablet-landscape-up {
                width: 100%;
              }

              @include for-tablet-portrait-up {
                width: 100%;
              }

              .mat-form-field-wrapper {
                background-color: #f4f4f4;

                .mat-form-field-flex {
                  .mat-form-field-infix {
                    input {
                      max-width: calc(100% - 30px);
                    }
                  }

                  .mat-form-field-suffix {
                    .clear-button {
                      position: absolute;
                      top: -8px;
                    }
                  }
                }
              }
            }
          }
        }

        .mat-menu-container {
          overflow: auto;
          height: calc(100vh - 200px);
          max-height: 240px;
          padding-right: 10px;
          margin-top: 0 !important;

          button.mat-menu-item {
            padding: 0 24px;
            height: 45px;
            color: color(secondary);
            font-size: 1rem;
            letter-spacing: 0.57px;
            line-height: 23px;
            text-align: left;
            padding-top: 4px;
            font-family: $ftci-font-regular;
            text-transform: capitalize;

            @include for-tablet-portrait-up {
              border-bottom: solid 1px color(border-c);
            }

            @include for-phone-only {
              border-bottom: solid 1px color(border-c);
            }

            &.active {
              font-weight: bold;
            }
          }

          button.mat-menu-item.active {
            font-family: $ftci-font-bold2;
          }

          .mat-selection-list {
            padding-top: 0 !important;

            .mat-list-option {
              .mat-list-item-content {
                .mat-list-item-ripple {
                  display: none;
                }

                .mat-list-text {
                  @extend .paragraph;
                  font-family: $ftci-font-regular;

                  .mat-line {
                    position: relative;
                    top: 3px;
                  }
                }

                .mat-pseudo-checkbox {
                  width: 20px;
                  height: 20px;
                  border-width: 1px;
                  border: solid 1px color(light);
                }

                .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
                  background: $white;
                }

                .mat-pseudo-checkbox-checked::after {
                  top: 2px;
                  left: 0px;
                  width: 14px;
                  height: 14px;
                  background: color(primary);
                  transform: none;
                  border-radius: 2px;
                }
              }
            }
          }

          .mat-list {
            padding-top: 0;

            .mat-list-item {
              .mat-list-item-content {
                .mat-list-text {
                  .mat-line {
                    padding: 1px 0;
                  }
                }
              }

              &.selected {
                .mat-list-item-content {
                  .mat-list-text {
                    .mat-line {
                      .mat-icon {
                        color: color(primary);
                        position: absolute;
                        top: 12px;
                        right: 10px;
                        font-size: 20px;
                      }
                    }
                  }
                }
              }

              &:hover {
                background-color: rgba(0, 0, 0, .04);
                cursor: pointer;
              }

              .mat-icon {
                @extend .paragraph;
                width: auto;
                height: auto;
              }
            }

            &.mat-list-base {
              .mat-line {
                width: 95%;

                //padding-top: 20px;
                &.search-error {
                  padding-top: 12px;
                }
              }
            }
          }
        }
      }

      .mat-menu-container::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background: color(border-c-light);
        border-radius: 6px;
      }

      .mat-menu-container::-webkit-scrollbar-thumb {
        background: #C8C8C8;
        border-radius: 6px;
        padding: 0;

        &:hover,
        &:active {
          background: #C8C8C8;
        }

      }

      .mat-menu-container::-webkit-scrollbar-track {
        background: color(border-c-light);
        border-radius: 6px;
      }

      &.no-padding {
        padding: 0;
      }

      /* Main Navigation droup down menu */
      &.header-menu-panel {
        width: max-content !important;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        height: fit-content;
        border-radius: 0;
        outline: 0;
        min-width: 266px;
        min-height: auto;
        padding: 0;

        .mat-menu-content {
          padding: 0;

          .mat-menu-item {
            border-bottom: 1px solid color(border-c);
            padding: 0 !important;

            span {
              position: relative;
              top: 2px;
              padding: 12px 24px !important;
            }

            .mat-menu-ripple {
              display: none;
            }
          }

          .mat-menu-item:hover {
            background-color: #E7E7E7;
          }

          .mat-menu-item.active {
            span {
              font-family: $ftci-font-bold2;
            }
          }

          .mat-menu-item.active::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 6px;
            border-color: transparent transparent transparent color(ftc);
            position: absolute;
            top: calc(50% - 5px);
            left: 10px;
          }

          .mat-menu-item.mat-menu-item-submenu-trigger::before {
            left: 10px;
          }

          .mat-menu-item.mat-menu-item-submenu-trigger:hover::before {
            border-width: 5px 5px 5px 0;
            border-color: transparent color(ftc) transparent transparent;
            top: 50%;
            left: 10px;
          }

          .mat-menu-item:last-child {
            border: 0;
            padding-bottom: 24px !important;
          }

          .mat-menu-item-submenu-trigger::after {
            border: none;
            display: none;
          }

          .mat-menu-item-submenu-trigger {
            padding: 0;
            padding-left: 32px;
          }

          .mat-menu-item-submenu-trigger::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 5px 0;
            border-color: transparent currentColor transparent transparent;
            content: "";
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 16px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }

        &.sub-menu {
          margin-top: -64px;

          .mat-menu-item {
            padding-left: 24px;
          }
        }
      }
      .mat-menu-item {
        padding-left: 24px;
      }

      /*Main Navigation droup down menu End*/
    }

    .ft-autocomplete-select-panel {
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      max-height: 306px;
      width: calc(100% - 2px);
      margin-left: 1px;
      border-radius: 0;

      .mat-option {
        height: auto;
        padding: 12px 20px;
        font-family: $ftci-font-regular;
        font-size: 1rem;
        color: #333333;
        line-height: 23px;
        width: 100%;
        max-width: 100%;

        .mat-option-text {
          margin-bottom: -5px;

          .bold {
            font-family: $ftci-font-bold2;
          }

          span.sub-text {
            display: block;
            font-family: $ftci-font-oblique;
            font-size: 0.875rem;
          }
        }
      }

      .mat-option:hover {
        //border-bottom: 1px solid #333;
        background: #E7E7E7 !important;
      }

      .mat-option.not-clickable {
        //border-bottom: none;
        background: #E7E7E7 !important;
      }
    }
  }
}

/* Input */
input:-internal-autofill-selected {
  background: $input-bg !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px $input-bg inset;
  -webkit-box-shadow: 0 0 0px 1000px $input-bg inset;
}


/* Webkit Scrollbar */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: color(border-c-light);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #C8C8C8;
  border-radius: 6px;
  padding: 0;
  height: 80px;

  &:hover,
  &:active {
    background: #C8C8C8;
  }
}

*::-webkit-scrollbar-track {
  background: color(border-c-light);
  border-radius: 0;
}


/* Static Table*/

.static-table {
  display: table;
  width: 100%;
}

.divtablerow {
  display: table-row;
}

.table-caption.bg {
  background-color: #E7E7E7;
  display: table;
  padding: 13px 10px;
  width: calc(100% - 20px);
  text-align: left;
  border-bottom: solid 1px color(light);
}

.divtablecell,
.divtablehead {
  display: table-cell;
  text-align: left;
}

.divtablecell {
  padding: 13px 10px;
  border-bottom: 1px solid color(light);
}

.divtablehead {
  padding: 8px 10px;
  border-bottom: 1px solid color(secondary);
  position: relative;

  h3 {
    //position: absolute;
    bottom: 8px;
  }
}

.divtableheading {
  display: table-header-group;
  font-weight: bold;

  .divtablehead {
    vertical-align: bottom;
  }
}

.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.divtablebody {
  display: table-row-group;
}

/* Static Table end */

.no-data-message {
  margin-top: 30px;

  @include for-desktop-small {
    margin-top: 24px;
  }

  @include for-tablet-landscape-up {
    margin-top: 24px;
  }

  @include for-tablet-portrait-up {
    margin: 40px 24px;
  }

  @include for-phone-only {
    margin: 40px 16px;
  }
}

/*Fonts Styling*/
.paragraph {
  @include font-size($p-font-sizes);
  @include font-lign-height($p-font-lign-height);
}

.paragraph-regular {
  @extend .paragraph;
  font-family: $ftci-font-regular;
  color: color(secondary);
}

.paragraph-bold {
  @extend .paragraph;
  font-family: $ftci-font-bold2;
  color: color(secondary);
}

.paragraph-c {
  @extend .paragraph;
  font-family: $ftci-font-condensed;
  color: color(secondary);
}

.paragraph-c-bold {
  @extend .paragraph;
  font-family: $ftci-font-condensed-bold;
  color: color(secondary);
}

.paragraph-sm {
  @include ps-font-size($ps-font-sizes);
  @include ps-lign-height($ps-font-lign-height);
  font-family: $ftci-font-regular;
}

.paragraph-sm-bold {
  @extend .paragraph-sm;
  font-family: $ftci-font-bold2;
}

.paragraph-c-sm {
  @extend .paragraph-sm;
  font-family: $ftci-font-condensed;
}

.paragraph-c-sm-bold {
  @extend .paragraph-sm;
  font-family: $ftci-font-condensed-bold;
}

.paragraph-obl {
  @extend .paragraph;
  font-family: $ftci-font-oblique;
}

.paragraph-sm-obl {
  @extend .paragraph-sm;
  font-family: $ftci-font-oblique;
}

.paragraph-lg {
  @include plg-font-size($plg-font-sizes);
  @include plg-lign-height($plg-font-lign-height);
  font-family: $ftci-font-regular;
  color: color(secondary);
}

.paragraph-lg-b {
  @extend .paragraph-lg;
  font-family: $ftci-font-bold2;
}

.paragraph-lg-c {
  @extend .paragraph-lg;
  font-family: $ftci-font-condensed;
  color: color(secondary);
}

.paragraph-lg-c-bold {
  @extend .paragraph-lg;
  font-family: $ftci-font-condensed-bold;
  color: color(secondary);
}

.paragraph-xl {
  @include pxl-font-size($pxl-font-sizes);
  @include pxl-lign-height($pxl-font-lign-height);
  color: color(secondary);
}

.paragraph-xl-b {
  @extend .paragraph-xl;
  font-family: $ftci-font-bold2;
}

.paragraph-xs {
  @include pxs-font-size($pxs-font-sizes);
  @include pxs-lign-height($pxs-font-lign-height);
  color: color(secondary);
}

.paragraph-xs-b {
  @extend .paragraph-xs;
  font-family: $ftci-font-bold2;
}

.paragraph-xl-c {
  @extend .paragraph-xl;
  font-family: $ftci-font-condensed;
}

.paragraph-xl-c-b {
  @extend .paragraph-xl;
  font-family: $ftci-font-condensed-bold;
}

.h1 {
  @include h1-font-size($h1-font-sizes);
  @include h1-line-height($h1-font-line-height);
}

.h1-r {
  @extend .h1;
  font-family: $ftci-font-bold2;
  color: color(secondary)
}

.h1-c {
  @extend .h1;
  font-family: $ftci-font-condensed-bold;
  color: color(secondary)
}

.h1-c-b {
  @extend .h1;
  font-family: $ftci-font-condensed-bold;
  color: color(primary)
}

.h2 {
  @include h2-font-size($h2-font-sizes);
  @include h2-line-height($h2-font-line-height);
  font-family: $ftci-font-light;
}

.h3 {
  @include ps-font-size($h3-font-sizes);
  @include ps-lign-height($h3-font-line-height);
  font-family: $ftci-font-regular;
}

.h3-b {
  @extend .h3;
  font-family: $ftci-font-bold2;
}

/*LP Hero Banner Title*/
.banner-title {
  font-family: $ftci-font-condensed-bold;
  font-size: 2rem;
  line-height: 2.8125rem;

  @include for-desktop-small {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  @include for-tablet-landscape-up {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  @include for-tablet-portrait-up {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  @include for-phone-only {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

/* LP Hero By Line */
.banner-titlel-line {
  @extend .paragraph-sm;
  font-family: $ftci-font-narrow-light-obl;
}

/* Animation Key Frames */
.slide-down {
  animation-name: slide-down;
  -webkit-animation-name: slide-down;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

.slide-up {
  animation-name: slide-up;
  -webkit-animation-name: slide-up;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
}

@keyframes slide-up {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(0%);
  }

  100% {
    -webkit-transform: translateY(-100%);
  }
}

/* End */

.alert-warning {
  @extend .paragraph-regular;
  padding: 10px 20px 7px 20px;
  background: #FFF1D2;
  margin-bottom: 30px;

  span {
    font-family: $ftci-font-bold2;
    color: color(secondary) !important;
  }
}

.alert-success {
  @extend .paragraph-regular;
  padding: 10px 20px;
  background: color(success);
  color: $white;

  span {
    font-family: $ftci-font-bold2;
  }
}

.ft-loading {
  width: 100%;
}

.safari-only {
  .mat-sort-header-container {
    .mat-sort-header-button {
      margin-bottom: 0 !important;
    }
  }
}


@media print {
  .main-container {
    /* height: intrinsic !important; */
    height: max-content !important;
  }

  .page-inner.content {
    width: 100% !important;
    min-width: 400px !important;
    max-width: 100% !important;
    height: 2000px;
  }

  .home-container {
    flex-direction: column !important;
  }

  .left-panel,
  .right-panel {
    width: calc(100% - 0px) !important;
  }

  .ft-insights,
  .ft-card,
  .footer-cont {
    display: none !important;
  }

  .ft-table-actions {
    width: 100%;

    .table-cont.home-table {
      height: auto !important;
    }

    .filter-cont.home-table {
      display: none !important;

      mat-table {
        width: 100% !important;

        .mat-header-row {
          width: 100% !important;
        }

        .mat-header-cell,
        .mat-cell {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

.ftci-table {
  display: table;
  width: 100%;
  font-family: $ftci-font-regular;

  .ftci-table-body {
    display: table-row-group;

    .ftci-table-row:last-child {
      .ftci-table-cell {
        //border-bottom: 0;
      }
    }
  }

  .ftci-table-row {
    display: table-row;
  }

  .has-highlight {
    background: rgba(53, 103, 149, 0.08) !important;
  }

  .ftci-table-heading {
    display: table-header-group;
    font-weight: bold;

    .ftci-table-head {
      display: table-cell;
      //background-color: #F2F1E7;
      border-bottom: 1px solid #C9C9C9;
      padding: 0px 0px 14px 0px;

      .paragraph-regular {
        display: flex;
        font-family: $ftci-font-bold2;
        font-weight: 400;
        line-height: 24px;

        .tooltip-btn {
          background: none;
          border: none;
          margin-left: 8px;
          font-size: 20px;
          width: 20px;
          height: 20px;
          padding: 0;
        }

      }
    }
  }

  .ftci-table-cell {
    display: table-cell;
    padding: 14px 0px 10px 0px;
    border-bottom: 1px solid #C9C9C9;

    .mat-line {
      ::ng-deep span {
        &:first-child {
          max-width: 80% !important;

          @include for-tablet-landscape-up {
            max-width: 65% !important;
          }
        }
      }
    }

    &:nth-child(2) {
      max-width: 190px;
    }

    &:first-child {
      padding-left: 0;

      .ftci-table-cell-inner {
        .mat-icon {
          margin-right: 12px;
        }
      }
    }

    &:last-child {
      padding-right: 16px;
    }

    .ftci-table-cell-inner {
      display: block;

      p.action-status {
        margin-right: 60px;
        display: flex;
        align-items: center;

        span {
          position: relative;
          top: 2px;

          span {
            top: 0;
          }
        }

        .mat-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
          margin-right: 8px;
        }

        &.no-margin {
          margin-right: 0px;
        }
      }

      p {
        &.in-valid-format {
          color: color(error);
        }

        &.error {
          .mat-icon {
            color: color(error);
          }
        }
      }

      .icon-holder {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 100px;
        position: relative;
        margin-right: 16px;
      }

      .mat-icon {
        width: 14px;
        height: 16px;
        font-size: 16px;
        padding: 0;
      }

      .value-text {
        position: relative;
        display: inline-block;
      }
    }

    .investment-related {
      display: flex;
      position: relative;
      top: 6px;

      &.disabled {
        user-select: none;
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
      }

      .ft-dropdown {
        position: relative;
        top: 4px;
      }
    }

    .mat-slide-toggle {
      height: 24px;
      width: 38px;

      ::ng-deep .mat-slide-toggle-label {
        .mat-slide-toggle-bar {
          height: 24px;
          width: 38px;
          background-color: #E0E0E0;
          border-radius: 14.12px;

          .mat-slide-toggle-thumb-container {
            height: 24px;
            top: 2px;
            left: 2px;

            .mat-slide-toggle-thumb {
              width: 20px;
              height: 20px;
              background-color: $white;
              border-radius: 11.29px;
              box-shadow: 0.5px 0.5px 0 0 rgba(0, 0, 0, 0.35);
            }

            .mat-slide-toggle-ripple {
              display: none;
            }
          }
        }
      }
    }

    .mat-slide-toggle.mat-checked {
      ::ng-deep .mat-slide-toggle-label {
        .mat-slide-toggle-bar {
          background-color: #7A99AC;

          .mat-slide-toggle-thumb-container {
            left: 0;

            .mat-slide-toggle-thumb {
              right: 4px;
              left: unset !important;
            }
          }
        }
      }
    }
  }



  .action-status.retry {
    cursor: pointer;
  }

  .progress-bar {
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      color: color(secondary);
    }

    .mat-progress-bar {
      margin-left: 12px;
      width: 200px;

      // position: fixed;
      ::ng-deep .mat-progress-bar-buffer {
        color: color(border-c);
      }

      //mat-progress-bar-element
      ::ng-deep .mat-progress-bar-primary {
        &::after {
          color: color(primary);
        }
      }
    }
  }

  .clear-button {
    &.disabled {
      user-select: none;
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }

    padding: 0;
    height: auto;
    cursor: pointer;
    position: relative;
    top: 2px;
    right: 8px;
    color: color(secondary);
    background: transparent;
    border: 0;
    width: 18px;

    .mat-icon {
      font-size: 14px;
      height: auto;
    }
  }
}

/* Grid */
.ft-grid {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 auto 0 auto;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ft-grid.ft-grid--no-spacing {
  padding: 0;
}

.ft-cell {
  box-sizing: border-box;
}

.ft-cell--top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.ft-cell--middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.ft-cell--bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.ft-cell--stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.ft-grid.ft-grid--no-spacing>.ft-cell {
  margin: 0;
}

.ft-cell--order-1 {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.ft-cell--order-2 {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.ft-cell--order-3 {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}

.ft-cell--order-4 {
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
}

.ft-cell--order-5 {
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5;
}

.ft-cell--order-6 {
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6;
}

.ft-cell--order-7 {
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7;
}

.ft-cell--order-8 {
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8;
}

.ft-cell--order-9 {
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9;
}

.ft-cell--order-10 {
  -webkit-order: 10;
  -ms-flex-order: 10;
  order: 10;
}

.ft-cell--order-11 {
  -webkit-order: 11;
  -ms-flex-order: 11;
  order: 11;
}

.ft-cell--order-12 {
  -webkit-order: 12;
  -ms-flex-order: 12;
  order: 12;
}

@media (max-width: 479px) {
  .ft-grid {
    padding: 8px;
  }

  .ft-cell {
    margin: 8px;
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell {
    width: 100%;
  }

  .ft-cell--hide-phone {
    display: none !important;
  }

  .ft-cell--order-1-phone.ft-cell--order-1-phone {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .ft-cell--order-2-phone.ft-cell--order-2-phone {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .ft-cell--order-3-phone.ft-cell--order-3-phone {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .ft-cell--order-4-phone.ft-cell--order-4-phone {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .ft-cell--order-5-phone.ft-cell--order-5-phone {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .ft-cell--order-6-phone.ft-cell--order-6-phone {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .ft-cell--order-7-phone.ft-cell--order-7-phone {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .ft-cell--order-8-phone.ft-cell--order-8-phone {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .ft-cell--order-9-phone.ft-cell--order-9-phone {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .ft-cell--order-10-phone.ft-cell--order-10-phone {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .ft-cell--order-11-phone.ft-cell--order-11-phone {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .ft-cell--order-12-phone.ft-cell--order-12-phone {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .ft-cell--1-col,
  .ft-cell--1-col-phone.ft-cell--1-col-phone {
    width: calc(25% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--1-col,
  .ft-grid--no-spacing>.ft-cell--1-col-phone.ft-cell--1-col-phone {
    width: 25%;
  }

  .ft-cell--2-col,
  .ft-cell--2-col-phone.ft-cell--2-col-phone {
    width: calc(50% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--2-col,
  .ft-grid--no-spacing>.ft-cell--2-col-phone.ft-cell--2-col-phone {
    width: 50%;
  }

  .ft-cell--3-col,
  .ft-cell--3-col-phone.ft-cell--3-col-phone {
    width: calc(75% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--3-col,
  .ft-grid--no-spacing>.ft-cell--3-col-phone.ft-cell--3-col-phone {
    width: 75%;
  }

  .ft-cell--4-col,
  .ft-cell--4-col-phone.ft-cell--4-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--4-col,
  .ft-grid--no-spacing>.ft-cell--4-col-phone.ft-cell--4-col-phone {
    width: 100%;
  }

  .ft-cell--5-col,
  .ft-cell--5-col-phone.ft-cell--5-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--5-col,
  .ft-grid--no-spacing>.ft-cell--5-col-phone.ft-cell--5-col-phone {
    width: 100%;
  }

  .ft-cell--6-col,
  .ft-cell--6-col-phone.ft-cell--6-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--6-col,
  .ft-grid--no-spacing>.ft-cell--6-col-phone.ft-cell--6-col-phone {
    width: 100%;
  }

  .ft-cell--7-col,
  .ft-cell--7-col-phone.ft-cell--7-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--7-col,
  .ft-grid--no-spacing>.ft-cell--7-col-phone.ft-cell--7-col-phone {
    width: 100%;
  }

  .ft-cell--8-col,
  .ft-cell--8-col-phone.ft-cell--8-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--8-col,
  .ft-grid--no-spacing>.ft-cell--8-col-phone.ft-cell--8-col-phone {
    width: 100%;
  }

  .ft-cell--9-col,
  .ft-cell--9-col-phone.ft-cell--9-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--9-col,
  .ft-grid--no-spacing>.ft-cell--9-col-phone.ft-cell--9-col-phone {
    width: 100%;
  }

  .ft-cell--10-col,
  .ft-cell--10-col-phone.ft-cell--10-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--10-col,
  .ft-grid--no-spacing>.ft-cell--10-col-phone.ft-cell--10-col-phone {
    width: 100%;
  }

  .ft-cell--11-col,
  .ft-cell--11-col-phone.ft-cell--11-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--11-col,
  .ft-grid--no-spacing>.ft-cell--11-col-phone.ft-cell--11-col-phone {
    width: 100%;
  }

  .ft-cell--12-col,
  .ft-cell--12-col-phone.ft-cell--12-col-phone {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--12-col,
  .ft-grid--no-spacing>.ft-cell--12-col-phone.ft-cell--12-col-phone {
    width: 100%;
  }

  .ft-cell--1-offset,
  .ft-cell--1-offset-phone.ft-cell--1-offset-phone {
    margin-left: calc(25% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--1-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--1-offset-phone.ft-cell--1-offset-phone {
    margin-left: 25%;
  }

  .ft-cell--2-offset,
  .ft-cell--2-offset-phone.ft-cell--2-offset-phone {
    margin-left: calc(50% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--2-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--2-offset-phone.ft-cell--2-offset-phone {
    margin-left: 50%;
  }

  .ft-cell--3-offset,
  .ft-cell--3-offset-phone.ft-cell--3-offset-phone {
    margin-left: calc(75% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--3-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--3-offset-phone.ft-cell--3-offset-phone {
    margin-left: 75%;
  }
}

@media (min-width: 480px) and (max-width: 839px) {
  .ft-grid {
    padding: 8px;
  }

  .ft-cell {
    margin: 8px;
    width: calc(50% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell {
    width: 50%;
  }

  .ft-cell--hide-tablet {
    display: none !important;
  }

  .ft-cell--order-1-tablet.ft-cell--order-1-tablet {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .ft-cell--order-2-tablet.ft-cell--order-2-tablet {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .ft-cell--order-3-tablet.ft-cell--order-3-tablet {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .ft-cell--order-4-tablet.ft-cell--order-4-tablet {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .ft-cell--order-5-tablet.ft-cell--order-5-tablet {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .ft-cell--order-6-tablet.ft-cell--order-6-tablet {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .ft-cell--order-7-tablet.ft-cell--order-7-tablet {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .ft-cell--order-8-tablet.ft-cell--order-8-tablet {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .ft-cell--order-9-tablet.ft-cell--order-9-tablet {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .ft-cell--order-10-tablet.ft-cell--order-10-tablet {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .ft-cell--order-11-tablet.ft-cell--order-11-tablet {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .ft-cell--order-12-tablet.ft-cell--order-12-tablet {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .ft-cell--1-col,
  .ft-cell--1-col-tablet.ft-cell--1-col-tablet {
    width: calc(12.5% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--1-col,
  .ft-grid--no-spacing>.ft-cell--1-col-tablet.ft-cell--1-col-tablet {
    width: 12.5%;
  }

  .ft-cell--2-col,
  .ft-cell--2-col-tablet.ft-cell--2-col-tablet {
    width: calc(25% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--2-col,
  .ft-grid--no-spacing>.ft-cell--2-col-tablet.ft-cell--2-col-tablet {
    width: 25%;
  }

  .ft-cell--3-col,
  .ft-cell--3-col-tablet.ft-cell--3-col-tablet {
    width: calc(37.5% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--3-col,
  .ft-grid--no-spacing>.ft-cell--3-col-tablet.ft-cell--3-col-tablet {
    width: 37.5%;
  }

  .ft-cell--4-col,
  .ft-cell--4-col-tablet.ft-cell--4-col-tablet {
    width: calc(50% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--4-col,
  .ft-grid--no-spacing>.ft-cell--4-col-tablet.ft-cell--4-col-tablet {
    width: 50%;
  }

  .ft-cell--5-col,
  .ft-cell--5-col-tablet.ft-cell--5-col-tablet {
    width: calc(62.5% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--5-col,
  .ft-grid--no-spacing>.ft-cell--5-col-tablet.ft-cell--5-col-tablet {
    width: 62.5%;
  }

  .ft-cell--6-col,
  .ft-cell--6-col-tablet.ft-cell--6-col-tablet {
    width: calc(75% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--6-col,
  .ft-grid--no-spacing>.ft-cell--6-col-tablet.ft-cell--6-col-tablet {
    width: 75%;
  }

  .ft-cell--7-col,
  .ft-cell--7-col-tablet.ft-cell--7-col-tablet {
    width: calc(87.5% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--7-col,
  .ft-grid--no-spacing>.ft-cell--7-col-tablet.ft-cell--7-col-tablet {
    width: 87.5%;
  }

  .ft-cell--8-col,
  .ft-cell--8-col-tablet.ft-cell--8-col-tablet {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--8-col,
  .ft-grid--no-spacing>.ft-cell--8-col-tablet.ft-cell--8-col-tablet {
    width: 100%;
  }

  .ft-cell--9-col,
  .ft-cell--9-col-tablet.ft-cell--9-col-tablet {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--9-col,
  .ft-grid--no-spacing>.ft-cell--9-col-tablet.ft-cell--9-col-tablet {
    width: 100%;
  }

  .ft-cell--10-col,
  .ft-cell--10-col-tablet.ft-cell--10-col-tablet {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--10-col,
  .ft-grid--no-spacing>.ft-cell--10-col-tablet.ft-cell--10-col-tablet {
    width: 100%;
  }

  .ft-cell--11-col,
  .ft-cell--11-col-tablet.ft-cell--11-col-tablet {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--11-col,
  .ft-grid--no-spacing>.ft-cell--11-col-tablet.ft-cell--11-col-tablet {
    width: 100%;
  }

  .ft-cell--12-col,
  .ft-cell--12-col-tablet.ft-cell--12-col-tablet {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--12-col,
  .ft-grid--no-spacing>.ft-cell--12-col-tablet.ft-cell--12-col-tablet {
    width: 100%;
  }

  .ft-cell--1-offset,
  .ft-cell--1-offset-tablet.ft-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--1-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--1-offset-tablet.ft-cell--1-offset-tablet {
    margin-left: 12.5%;
  }

  .ft-cell--2-offset,
  .ft-cell--2-offset-tablet.ft-cell--2-offset-tablet {
    margin-left: calc(25% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--2-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--2-offset-tablet.ft-cell--2-offset-tablet {
    margin-left: 25%;
  }

  .ft-cell--3-offset,
  .ft-cell--3-offset-tablet.ft-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--3-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--3-offset-tablet.ft-cell--3-offset-tablet {
    margin-left: 37.5%;
  }

  .ft-cell--4-offset,
  .ft-cell--4-offset-tablet.ft-cell--4-offset-tablet {
    margin-left: calc(50% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--4-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--4-offset-tablet.ft-cell--4-offset-tablet {
    margin-left: 50%;
  }

  .ft-cell--5-offset,
  .ft-cell--5-offset-tablet.ft-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--5-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--5-offset-tablet.ft-cell--5-offset-tablet {
    margin-left: 62.5%;
  }

  .ft-cell--6-offset,
  .ft-cell--6-offset-tablet.ft-cell--6-offset-tablet {
    margin-left: calc(75% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--6-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--6-offset-tablet.ft-cell--6-offset-tablet {
    margin-left: 75%;
  }

  .ft-cell--7-offset,
  .ft-cell--7-offset-tablet.ft-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--7-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--7-offset-tablet.ft-cell--7-offset-tablet {
    margin-left: 87.5%;
  }
}

@media (min-width: 840px) {
  .ft-grid {
    padding: 8px;
  }

  .ft-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell {
    width: 33.3333333333%;
  }

  .ft-cell--hide-desktop {
    display: none !important;
  }

  .ft-cell--order-1-desktop.ft-cell--order-1-desktop {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .ft-cell--order-2-desktop.ft-cell--order-2-desktop {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .ft-cell--order-3-desktop.ft-cell--order-3-desktop {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .ft-cell--order-4-desktop.ft-cell--order-4-desktop {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .ft-cell--order-5-desktop.ft-cell--order-5-desktop {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .ft-cell--order-6-desktop.ft-cell--order-6-desktop {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .ft-cell--order-7-desktop.ft-cell--order-7-desktop {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .ft-cell--order-8-desktop.ft-cell--order-8-desktop {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .ft-cell--order-9-desktop.ft-cell--order-9-desktop {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .ft-cell--order-10-desktop.ft-cell--order-10-desktop {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .ft-cell--order-11-desktop.ft-cell--order-11-desktop {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .ft-cell--order-12-desktop.ft-cell--order-12-desktop {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .ft-cell--1-col,
  .ft-cell--1-col-desktop.ft-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--1-col,
  .ft-grid--no-spacing>.ft-cell--1-col-desktop.ft-cell--1-col-desktop {
    width: 8.3333333333%;
  }

  .ft-cell--2-col,
  .ft-cell--2-col-desktop.ft-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--2-col,
  .ft-grid--no-spacing>.ft-cell--2-col-desktop.ft-cell--2-col-desktop {
    width: 16.6666666667%;
  }

  .ft-cell--3-col,
  .ft-cell--3-col-desktop.ft-cell--3-col-desktop {
    width: calc(25% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--3-col,
  .ft-grid--no-spacing>.ft-cell--3-col-desktop.ft-cell--3-col-desktop {
    width: 25%;
  }

  .ft-cell--4-col,
  .ft-cell--4-col-desktop.ft-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--4-col,
  .ft-grid--no-spacing>.ft-cell--4-col-desktop.ft-cell--4-col-desktop {
    width: 33.3333333333%;
  }

  .ft-cell--5-col,
  .ft-cell--5-col-desktop.ft-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--5-col,
  .ft-grid--no-spacing>.ft-cell--5-col-desktop.ft-cell--5-col-desktop {
    width: 41.6666666667%;
  }

  .ft-cell--6-col,
  .ft-cell--6-col-desktop.ft-cell--6-col-desktop {
    width: calc(50% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--6-col,
  .ft-grid--no-spacing>.ft-cell--6-col-desktop.ft-cell--6-col-desktop {
    width: 50%;
  }

  .ft-cell--7-col,
  .ft-cell--7-col-desktop.ft-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--7-col,
  .ft-grid--no-spacing>.ft-cell--7-col-desktop.ft-cell--7-col-desktop {
    width: 58.3333333333%;
  }

  .ft-cell--8-col,
  .ft-cell--8-col-desktop.ft-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--8-col,
  .ft-grid--no-spacing>.ft-cell--8-col-desktop.ft-cell--8-col-desktop {
    width: 66.6666666667%;
  }

  .ft-cell--9-col,
  .ft-cell--9-col-desktop.ft-cell--9-col-desktop {
    width: calc(75% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--9-col,
  .ft-grid--no-spacing>.ft-cell--9-col-desktop.ft-cell--9-col-desktop {
    width: 75%;
  }

  .ft-cell--10-col,
  .ft-cell--10-col-desktop.ft-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--10-col,
  .ft-grid--no-spacing>.ft-cell--10-col-desktop.ft-cell--10-col-desktop {
    width: 83.3333333333%;
  }

  .ft-cell--11-col,
  .ft-cell--11-col-desktop.ft-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--11-col,
  .ft-grid--no-spacing>.ft-cell--11-col-desktop.ft-cell--11-col-desktop {
    width: 91.6666666667%;
  }

  .ft-cell--12-col,
  .ft-cell--12-col-desktop.ft-cell--12-col-desktop {
    width: calc(100% - 16px);
  }

  .ft-grid--no-spacing>.ft-cell--12-col,
  .ft-grid--no-spacing>.ft-cell--12-col-desktop.ft-cell--12-col-desktop {
    width: 100%;
  }

  .ft-cell--1-offset,
  .ft-cell--1-offset-desktop.ft-cell--1-offset-desktop {
    margin-left: calc(8.3333333333% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--1-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--1-offset-desktop.ft-cell--1-offset-desktop {
    margin-left: 8.3333333333%;
  }

  .ft-cell--2-offset,
  .ft-cell--2-offset-desktop.ft-cell--2-offset-desktop {
    margin-left: calc(16.6666666667% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--2-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--2-offset-desktop.ft-cell--2-offset-desktop {
    margin-left: 16.6666666667%;
  }

  .ft-cell--3-offset,
  .ft-cell--3-offset-desktop.ft-cell--3-offset-desktop {
    margin-left: calc(25% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--3-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--3-offset-desktop.ft-cell--3-offset-desktop {
    margin-left: 25%;
  }

  .ft-cell--4-offset,
  .ft-cell--4-offset-desktop.ft-cell--4-offset-desktop {
    margin-left: calc(33.3333333333% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--4-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--4-offset-desktop.ft-cell--4-offset-desktop {
    margin-left: 33.3333333333%;
  }

  .ft-cell--5-offset,
  .ft-cell--5-offset-desktop.ft-cell--5-offset-desktop {
    margin-left: calc(41.6666666667% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--5-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--5-offset-desktop.ft-cell--5-offset-desktop {
    margin-left: 41.6666666667%;
  }

  .ft-cell--6-offset,
  .ft-cell--6-offset-desktop.ft-cell--6-offset-desktop {
    margin-left: calc(50% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--6-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--6-offset-desktop.ft-cell--6-offset-desktop {
    margin-left: 50%;
  }

  .ft-cell--7-offset,
  .ft-cell--7-offset-desktop.ft-cell--7-offset-desktop {
    margin-left: calc(58.3333333333% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--7-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--7-offset-desktop.ft-cell--7-offset-desktop {
    margin-left: 58.3333333333%;
  }

  .ft-cell--8-offset,
  .ft-cell--8-offset-desktop.ft-cell--8-offset-desktop {
    margin-left: calc(66.6666666667% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--8-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--8-offset-desktop.ft-cell--8-offset-desktop {
    margin-left: 66.6666666667%;
  }

  .ft-cell--9-offset,
  .ft-cell--9-offset-desktop.ft-cell--9-offset-desktop {
    margin-left: calc(75% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--9-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--9-offset-desktop.ft-cell--9-offset-desktop {
    margin-left: 75%;
  }

  .ft-cell--10-offset,
  .ft-cell--10-offset-desktop.ft-cell--10-offset-desktop {
    margin-left: calc(83.3333333333% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--10-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--10-offset-desktop.ft-cell--10-offset-desktop {
    margin-left: 83.3333333333%;
  }

  .ft-cell--11-offset,
  .ft-cell--11-offset-desktop.ft-cell--11-offset-desktop {
    margin-left: calc(91.6666666667% + 8px);
  }

  .ft-grid.ft-grid--no-spacing>.ft-cell--11-offset,
  .ft-grid.ft-grid--no-spacing>.ft-cell--11-offset-desktop.ft-cell--11-offset-desktop {
    margin-left: 91.6666666667%;
  }
}
