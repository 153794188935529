/*
 * Application global variables.
 */

/* Fonts-stack */
$ftci-font-regular: "TradeGothicLTStd";
$ftci-font-bold: "TradeGothicLTStd-Bold";
$ftci-font-bold2: "TradeGothicLTStd-Bd2";
$ftci-font-light: "TradeGothicLTStd-Light";
$ftci-font-condensed-bold: "TradeGothicLTStd-BdCn20";
$ftci-font-condensed: "TradeGothicLTStd-Cn18";
$ftci-font-narrow-light-obl: "TradeGothicLTStd-Cn18Obl";
$ftci-font-oblique: "TradeGothicLTStd-Obl";
$ftci-font-oblique-bold:"TradeGothicLTStd-BoldObl";

/* Colors */
$white: #fff;
$black: #000;
$yellow: #ffc107;
$green: #007232;
$error: #D70000;
$sort-highlight: #BC0000;
$logo-color: #bc0100;
$primary-color: #4f758b;
$secondary-color: #333333;
$placeholder-color: #969696;
$input-bg: #eeeeee;
$text-muted: #646464;
$border-color: #c9c9c9;
$border-color-light: #E4E4E6;
$mat-sidenav-bg: #F2F1E7;
$secondary-bg:#f4f4f4;
$theme-colors: (
  logo-color: $logo-color,
  primary: $primary-color,
  secondary: $secondary-color,
  success: $green,
  error: $error,
  warning: $yellow,
  danger: $error,
  light: $text-muted,
  black: $black,
  border-c: $border-color,
  sort-highlight: $sort-highlight,
  border-c-light: $border-color-light);

/* functions */
@function color($color-name) {
  @return map-get($theme-colors, $color-name);
}

/* mixins-includes */
@mixin box-shadow($top, $left, $blur, $radius, $color) {
  -webkit-box-shadow: $top $left $blur $radius $color;
  -moz-box-shadow: $top $left $blur $radius $color;
  box-shadow: $top $left $blur $radius $color;
}

/* RWD Mixins */
@mixin for-phone-only {
  @media (max-width: 599.5px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) and (max-width: 959.5px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 960px) and (max-width: 1279.5px) {
    @content;
  }
}

@mixin for-desktop-small {
  @media (min-width: 1280px) and (max-width:1599.5px) {
    @content;
  }
}

@mixin for-lt-desktop-small {
  @media (max-width:1279.5px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1600px) and (max-width: 1919.5px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1920px) and (max-width: 5000px) {
    @content;
  }
}

/*Font size*/
/* P-regular */
$p-font-sizes: (null : 1rem,
  599px : 1rem,
  600px : 1rem,
  960px : 0.9375rem,
  1280px: 0.875rem,
  1600px: 1rem,
  1920px: 1rem);

@mixin font-size($fs-map) {
  @each $fs-breakpoint,
  $fs-font-size in $fs-map {
    @if $fs-breakpoint==null {
      font-size: $fs-font-size;
    }
    @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }

  }
}

/* P lignHeight */
$p-font-lign-height: (null : 1.4375rem,
  599px : 1.4375rem,
  600px : 1.4375rem,
  960px : 1.3125rem,
  1280px: 1.25rem,
  1600px: 1.4375rem,
  1920px: 1.4375rem);

@mixin font-lign-height($fl-map) {
  @each $fl-breakpoint,
  $fl-line-height in $fl-map {
    @if $fl-breakpoint==null {
      line-height: $fl-line-height;
    }
    @else {
      @media screen and (min-width: $fl-breakpoint) {
        line-height: $fl-line-height;
      }
    }
  }
}

/* P Small */
$ps-font-sizes: (null : 0.9375rem,
  599px : 0.9375rem,
  600px : 0.9375rem,
  960px : 0.8125rem,
  1280px: 0.75rem,
  1600px: 0.875rem,
  1920px: 0.875rem);

@mixin ps-font-size($psfs-map) {
  @each $psfs-breakpoint,
  $psfs-font-size in $psfs-map {
    @if $psfs-breakpoint==null {
      font-size: $psfs-font-size;
    }
    @else {
      @media screen and (min-width: $psfs-breakpoint) {
        font-size: $psfs-font-size;
      }
    }
  }
}

/* P Small lignHeight */
$ps-font-lign-height: (null : 1.3125rem,
  599px : 1.3125rem,
  600px : 1.3125rem,
  960px : 1.125rem,
  1280px: 1.0625rem,
  1600px: 1.25rem,
  1920px: 1.25rem);

@mixin ps-lign-height($psfl-map) {
  @each $psfl-breakpoint,
  $psfl-line-height in $psfl-map {
    @if $psfl-breakpoint==null {
      line-height: $psfl-line-height;
    }
    @else {
      @media screen and (min-width: $psfl-breakpoint) {
        line-height: $psfl-line-height;
      }
    }
  }
}

/* P Large */
$plg-font-sizes: (null : 1.125rem,
  599px : 1.125rem,
  600px : 1.125rem,
  960px : 1.0625rem,
  1280px: 1rem,
  1600px: 1.125rem,
  1920px: 1.125rem);

@mixin plg-font-size($plgfs-map) {
  @each $plgfs-breakpoint,
  $plgfs-font-size in $plgfs-map {
    @if $plgfs-breakpoint==null {
      font-size: $plgfs-font-size;
    }
    @else {
      @media screen and (min-width: $plgfs-breakpoint) {
        font-size: $plgfs-font-size;
      }
    }
  }
}

/* P Large lignHeight */
$plg-font-lign-height: (null : 1.5625rem,
  599px : 1.5625rem,
  600px : 1.5625rem,
  960px : 1.5rem,
  1280px: 1.4375rem,
  1600px: 1.5625rem,
  1920px: 1.5625rem);

@mixin plg-lign-height($plgfl-map) {
  @each $plgfl-breakpoint,
  $plgfl-line-height in $plgfl-map {
    @if $plgfl-breakpoint==null {
      line-height: $plgfl-line-height;
    }
    @else {
      @media screen and (min-width: $plgfl-breakpoint) {
        line-height: $plgfl-line-height;
      }
    }
  }
}


/* P XLarge */
$pxl-font-sizes: (null : 1.25rem,
  599px : 1.25rem,
  600px : 1.25rem,
  960px : 1.1875rem,
  1280px: 1.125rem,
  1600px: 1.25rem,
  1920px: 1.25rem);

@mixin pxl-font-size($pxlfs-map) {
  @each $pxlfs-breakpoint,
  $pxlfs-font-size in $pxlfs-map {
    @if $pxlfs-breakpoint==null {
      font-size: $pxlfs-font-size;
    }
    @else {
      @media screen and (min-width: $pxlfs-breakpoint) {
        font-size: $pxlfs-font-size;
      }
    }
  }
}

/* P XLarge lignHeight */
$pxl-font-lign-height: (null : 1.75rem,
  599px : 1.75rem,
  600px : 1.75rem,
  960px : 1.6875rem,
  1280px: 1.5625rem,
  1600px: 1.75rem,
  1920px: 1.75rem);

@mixin pxl-lign-height($pxlfl-map) {
  @each $pxlfl-breakpoint,
  $pxlfl-line-height in $pxlfl-map {
    @if $pxlfl-breakpoint==null {
      line-height: $pxlfl-line-height;
    }
    @else {
      @media screen and (min-width: $pxlfl-breakpoint) {
        line-height: $pxlfl-line-height;
      }
    }
  }
}


/* P XSmall */
$pxs-font-sizes: (null : 0.8125rem,
  599px : 0.8125rem,
  600px : 0.8125rem,
  960px : 0.75rem,
  1280px: 0.75rem,
  1600px: 0.75rem,
  1920px: 0.75rem);

@mixin pxs-font-size($pxsfs-map) {
  @each $pxsfs-breakpoint,
  $pxsfs-font-size in $pxsfs-map {
    @if $pxsfs-breakpoint==null {
      font-size: $pxsfs-font-size;
    }
    @else {
      @media screen and (min-width: $pxsfs-breakpoint) {
        font-size: $pxsfs-font-size;
      }
    }
  }
}

/* P XSmall lignHeight */
$pxs-font-lign-height: (null : 1.75rem,
  599px : 1.125rem,
  600px : 1.125rem,
  960px : 1.0625rem,
  1280px: 1.0625rem,
  1600px: 1.0625rem,
  1920px: 1.0625rem);

@mixin pxs-lign-height($pxsfl-map) {
  @each $pxsfl-breakpoint,
  $pxsfl-line-height in $pxsfl-map {
    @if $pxsfl-breakpoint==null {
      line-height: $pxsfl-line-height;
    }
    @else {
      @media screen and (min-width: $pxsfl-breakpoint) {
        line-height: $pxsfl-line-height;
      }
    }
  }
}


/* H1 */
$h1-font-sizes: (null : 1.25rem,
  599px : 1.25rem,
  600px : 1.5rem,
  960px : 1.5rem,
  1280px: 1.375rem,
  1600px: 1.75rem,
  1920px: 1.75rem);

@mixin h1-font-size($h1fs-map) {
  @each $h1fs-breakpoint,
  $h1fs-font-size in $h1fs-map {
    @if $h1fs-breakpoint==null {
      font-size: $h1fs-font-size;
    }
    @else {
      @media screen and (min-width: $h1fs-breakpoint) {
        font-size: $h1fs-font-size;
      }
    }
  }
}

$h1-font-line-height: (null : 1.75rem,
  599px : 1.75rem,
  600px : 2.125rem,
  960px : 2.125rem,
  1280px: 1.9375rem,
  1600px: 2.4375rem,
  1920px: 2.4375rem);

@mixin h1-line-height($h1fl-map) {
  @each $h1fl-breakpoint,
  $h1fl-line-height in $h1fl-map {
    @if $h1fl-breakpoint==null {
      line-height: $h1fl-line-height;
    }
    @else {
      @media screen and (min-width: $h1fl-breakpoint) {
        line-height: $h1fl-line-height;
      }
    }
  }
}

/* H2 */
$h2-font-sizes: (null : 1.125rem,
  599px : 1.125rem,
  600px : 1.25rem,
  960px : 1.375rem,
  1280px: 1.25rem,
  1600px: 1.5rem,
  1920px: 1.5rem);

@mixin h2-font-size($h2fs-map) {
  @each $h2fs-breakpoint,
  $h2fs-font-size in $h2fs-map {
    @if $h2fs-breakpoint==null {
      font-size: $h2fs-font-size;
    }
    @else {
      @media screen and (min-width: $h2fs-breakpoint) {
        font-size: $h2fs-font-size;
      }
    }
  }
}

$h2-font-line-height: (null : 1.5625rem,
  599px : 1.5625rem,
  600px : 1.75rem,
  960px : 1.9375rem,
  1280px: 1.75rem,
  1600px: 2.125rem,
  1920px: 2.125rem);

@mixin h2-line-height($h2fl-map) {
  @each $h2fl-breakpoint,
  $h2fl-line-height in $h2fl-map {
    @if $h2fl-breakpoint==null {
      line-height: $h2fl-line-height;
    }
    @else {
      @media screen and (min-width: $h2fl-breakpoint) {
        line-height: $h2fl-line-height;
      }
    }
  }
}

/* H3 */
$h3-font-sizes: (null : 0.8125rem,
  599px : 0.8125rem,
  600px : 0.8125rem,
  960px : 0.8125rem,
  1280px: 0.75rem,
  1600px: 0.875rem,
  1920px: 0.875rem);

@mixin h3-font-size($h3fs-map) {
  @each $h3fs-breakpoint,
  $h3fs-font-size in $h3fs-map {
    @if $h3fs-breakpoint==null {
      font-size: $h3fs-font-size;
    }
    @else {
      @media screen and (min-width: $h3fs-breakpoint) {
        font-size: $h3fs-font-size;
      }
    }
  }
}

$h3-font-line-height: (null : 1.125rem,
  599px : 1.125rem,
  600px : 1.125rem,
  960px : 1.125rem,
  1280px: 1.0625rem,
  1600px: 1.25rem,
  1920px: 1.25rem);

@mixin h2-line-height($h3fl-map) {
  @each $h3fl-breakpoint,
  $h3fl-line-height in $h3fl-map {
    @if $h3fl-breakpoint==null {
      line-height: $h3fl-line-height;
    }
    @else {
      @media screen and (min-width: $h3fl-breakpoint) {
        line-height: $h3fl-line-height;
      }
    }
  }
}
