/* You can add global styles to this file, and also import other style files */
@import "assets/themes/default/fonts.scss";
@import "assets/themes/default/theme-variables.scss";
@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';


a {
  color: color(primary);
  font-family: $ftci-font-bold2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
@font-face {
  font-family: 'TradegothicLTStd';
  // src: url('assets/fonts/TTCommonsPro-TypeType/TTCommonProRegular.otf');
  src: url('./assets/fonts/TTCommonsPro-TypeType/TTCommonProRegular.otf') format('opentype');
}
@font-face {
  font-family:'TradeGothicLTStd-Bd2';
  //src: url('assets/fonts/TTCommonsPro-TypeType/TTCommonsProDemiBold.otf');
  src: url('./assets/fonts/TTCommonsPro-TypeType/TTCommonsProDemiBold.otf') format('opentype');
}

.app-loading {
  position: absolute;
  top: 50%;
  width: 100%;
}

.app-loading img {
  height: 100px;
  width: 100px;
  position: absolute;
  top: -60px;
  left: calc(50% - 60px);
  padding: 0;
  background: color(ftc);
  border: 10px solid $white;
  border-radius: 3px;
}

.app-loading #percentage {
  color: color(ftc);
  position: absolute;
  bottom: -100px;
  left: calc(50% - 18px);
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }

  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }

  to {
    left: 110%;
    width: 10%;
  }
}

.btn-primary {
  background-color: #0061d5 !important;
  border-color: #0061d5 !important;
}

/*Error PAGE CSS*/
.error-main-cont {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-tablet-portrait-up {
    background: $white;
  }

  @include for-phone-only {
    background: $white;
  }

  .cont {
    width: 500px;
    padding: 60px 80px;
    background: $white;
    text-align: center;

    @include for-desktop-small {
      padding: 48px 60px;
    }

    @include for-tablet-landscape-up {
      padding: 48px 60px;
    }

    @include for-tablet-portrait-up {
      width: 100%;
      padding: 40px 48px;
    }

    @include for-phone-only {
      width: 100%;
      padding: 40px 16px;
    }

    .brand-logo {
      text-align: center;
      margin-bottom: 24px;

      @include for-tablet-portrait-up {
        margin-bottom: 40px;
      }

      @include for-phone-only {
        margin-bottom: 40px;
      }

      img {
        height: 60px;
        margin-top: 10px;

        @include for-tablet-portrait-up {
          height: 50px;
        }

        @include for-phone-only {
          height: 50px;
        }
      }
    }

    h1 {
      margin-bottom: 24px;

      @include for-tablet-portrait-up {
        margin-bottom: 32px;
      }

      @include for-phone-only {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 16px;

      a.h3-b {
        color: color(primary);
      }
    }

    .call {
      margin-bottom: 40px;

      a {
        font-size: 32px;
        font-weight: 700;
        text-decoration: none;
      }
    }

    button {
      margin-bottom: 32px;
      min-width: 240px;

      @include for-tablet-portrait-up {
        margin-bottom: 24px;
      }

      @include for-phone-only {
        margin-bottom: 24px;
      }
    }
  }

  .low-browser {
    width: 500px;
    padding: 60px 80px;
    background: $white;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    /*move the object to the center of the parent object*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @include for-desktop-small {
      padding: 48px 60px;
    }

    @include for-tablet-landscape-up {
      padding: 48px 60px;
    }

    @include for-tablet-portrait-up {
      width: 80%;
      padding: 40px 48px;
    }

    @include for-phone-only {
      width: 80%;
      padding: 40px 16px;
    }

    .brand-logo {
      text-align: center;
      margin-bottom: 24px;

      @include for-tablet-portrait-up {
        margin-bottom: 40px;
      }

      @include for-phone-only {
        margin-bottom: 40px;
      }

      img {
        height: 60px;
        margin-top: 10px;

        @include for-tablet-portrait-up {
          height: 50px;
        }

        @include for-phone-only {
          height: 50px;
        }
      }
    }

    h1 {
      margin-bottom: 24px;

      @include for-tablet-portrait-up {
        margin-bottom: 32px;
      }

      @include for-phone-only {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 16px;

      a.h3-b {
        color: color(primary);
      }
    }

    .spacer {
      margin-bottom: 40px;

      @include for-tablet-portrait-up {
        margin-bottom: 48px;
      }

      @include for-phone-only {
        margin-bottom: 48px;
      }
    }

    button {
      margin-bottom: 32px;
      min-width: 240px;

      @include for-tablet-portrait-up {
        margin-bottom: 24px;
      }

      @include for-phone-only {
        margin-bottom: 24px;
      }
    }

    .minimum-browser {
      display: flex;
      flex-wrap: wrap;
      min-height: 91px;
      margin-top: 24px;
      margin-bottom: 32px;
      list-style-type: none;

      @include for-phone-only {
        margin-left: 25px;
      }

      li {
        width: 45%;
        padding-left: 17px;
        margin-bottom: 8px;
        position: relative;
        color: color(light);
        text-align: left;
        display: inline-block;

        @include for-phone-only {
          width: 100%;
        }

        &::before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: color(light);
          position: absolute;
          top: 5px;
          left: 0;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.inner-page-form-input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.main-cont.browser-support {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
}

.browser-support.hidden-element {
  display: none !important;
}

.ft-ng-table-generic {
  .table-cont {
    .no-data-message {
      margin-left: 24px;
      position: absolute;
      top: 15%;
    }
  }
}

.work-in-progress{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-transform: uppercase;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.pdf-print-loader {
  .loader-container {
    width: unset !important;
    left: 10%;
    right: 0;
    background-color: rgba(255, 255, 255, .65);
    z-index: 999999 !important;
  }
}

/* New Style Overrides By Pavan */
.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    .ft-loading-dialog {
      max-width: 100% !important;
      transition: none !important;

      .mat-dialog-container {
        padding: 0;
        background: rgba(255, 255, 255, 0.85);
        box-shadow: none !important;
        .ft-ng-loading{ 
          .loader-container{          
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    z-index: 9;          
          }
        }        
      }
      
      
    }
    
    .ft-ng-box-document-viewer-panel {
      max-width: 100% !important;
      transition: none !important;

      .mat-dialog-container {
        padding: 0;
        background: rgba(255, 255, 255, 0.85);
        box-shadow: none !important;
      }
    }

    .ft-ng-document-viewer-panel {
      max-width: 100% !important;
      transition: none !important;

      .mat-dialog-container {
        padding: 0;
        background: rgba(255, 255, 255, 0.85);
        box-shadow: none !important;
      }
    }
  }
}

.FT-ARIAL{
  font-family: Arial;
}
.FT-ARIAL-BOLD{
  font-family:Arial;
  font-weight: bold;
}
.FT-ARIAL-ITALLIC{
  font-family: Arial;
  font-style: italic;
}
.FT-ARIAL-BOLD-ITALLIC{
  font-family: Arial;
  font-style: italic;
  font-weight: bold;
}
.FT-ARIAL-NARROW{
  font-family: 'Arial Narrow';
}
.FT-ARIAL-NARROW-ITALIC{
  font-family: 'Arial Narrow';
  font-style: italic;
}
.FT-ARIAL-NARROW-BOLD{
  font-family: 'Arial Narrow';
  font-weight: bold;
}
.FT-ARIAL-NARROW-ITALLIC-BOLD{
  font-family: 'Arial Narrow';
  font-weight: bold;
  font-style: italic;
}
.FT-TRADEGOTHIC{
  font-family: TradeGothicLTStd;
}
.FT-TRADEGOTHIC-BOLD{
  font-family: TradeGothicLTStd;
  font-weight: bold;
}
.FT-TRADEGOTHIC-ITALLIC{
  font-family: TradeGothicLTStd;
  font-style: italic;
}

.FT-TRADEGOTHIC-BOLD-ITALLIC{
  font-family: TradeGothicLTStd;
  font-style: italic;
  font-weight: bold;
}

.paragraph-c, html button.button, body button.button, html a.primary-button, html label.primary-button, html button.primary-button, body a.primary-button, body label.primary-button, body button.primary-button, html a.primary-button .mat-button-wrapper, html label.primary-button .mat-button-wrapper, html button.primary-button .mat-button-wrapper, body a.primary-button .mat-button-wrapper, body label.primary-button .mat-button-wrapper, body button.primary-button .mat-button-wrapper, html a.danger-button, html button.danger-button, body a.danger-button, body button.danger-button, html a.danger-button .mat-button-wrapper, html button.danger-button .mat-button-wrapper, body a.danger-button .mat-button-wrapper, body button.danger-button .mat-button-wrapper, html a.secondary-button, html label.secondary-button, html button.secondary-button, body a.secondary-button, body label.secondary-button, body button.secondary-button {
  font-family: TradeGothicLTStd-Bd2 !important;
}

button:focus-visible{  
  outline: 1px solid #3869ff;
  outline-offset: 2px;  
}